import { app } from 'o365-modules';
import { getOrCreateDataObject, getDataObjectById, dataObjectStore } from 'o365-dataobject';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';
import type App from 'o365.pwa.modules.client.dexie.objectStores.App.ts';

type IIDbDatabase = {
    value: Database,
    objectStores: Map<string, {
        value: ObjectStore,
        indexes: Map<string, {
            value: Index
        }>
    }>
};

export namespace DataObjectInitializer {
    export async function initializeDataObjects(_dataObjectConfigs: Map<string, any>): Promise<void> {
        let idbApp = await getApp();

        const indexedDbDatabases = await getIndexedDbDatabases(idbApp);

        const dataObjectConfigs = Array.from((_dataObjectConfigs).entries());

        const movedDataObjects = new Set<string>();
        const subConfigToDataObject = new Map<string, string>();
        const masterDetailMapping = new Map<string, string>();
        let i = 0;

        while (i < dataObjectConfigs.length) {
            const [dataObjectId, dataObjectConfig] = dataObjectConfigs[i];

            let moveToEnd = false;

            if (dataObjectConfig.offline?.subConfigs) {
                moveToEnd = validateSubConfigs(
                    dataObjectConfig.offline?.subConfigs,
                    dataObjectId,
                    subConfigToDataObject,
                    masterDetailMapping,
                    dataObjectConfigs,
                    i
                );
            }

            if (moveToEnd) {
                if (!movedDataObjects.has(dataObjectId)) {
                    movedDataObjects.add(dataObjectId);
                }

                continue;
            }

            if (movedDataObjects.has(dataObjectId)) {
                movedDataObjects.add(dataObjectId);
            }

            const dataObject = getDataObjectById(dataObjectId, app.id);

            if (dataObject.shouldEnableOffline === false) {
                i++;
                continue;
            }

            dataObject.enableOffline();

            createSyncObject(dataObjectId, dataObjectConfig);

            if (dataObjectConfig.offline?.subConfigs) {
                createSubConfigs(dataObjectConfig, dataObjectId);
            }

            const objectStoreId = dataObject.offline.objectStoreIdOverride ?? dataObject.id;

            const idbDatabaseCache = indexedDbDatabases.get('DEFAULT')!;

            const idbDatabase = idbDatabaseCache.value;

            let idbObjectStore = await IndexedDBHandler.getObjectStore(idbApp.id, idbDatabase.id, objectStoreId);

            await createObjectStore(
                dataObject,
                dataObjectConfig,
                objectStoreId,
                idbDatabaseCache,
                idbObjectStore,
                idbDatabase,
                idbApp
            );

            await createIndexes(
                dataObject,
                idbDatabaseCache,
                idbObjectStore,
                idbApp,
                idbDatabase
            )

            // TODO: We need a system to delete indexes that have been removed...

            i++;
        }
    }

    function validateSubConfigs(
        subConfigs: any,
        dataObjectId: string,
        subConfigToDataObject: Map<string, string>,
        masterDetailMapping: Map<string, string>,
        dataObjectConfigs: Array<[string, any]>,
        index: number): boolean {
        let moveToEnd = false;
        for (const subConfigKey of Object.keys(subConfigs)) {
            const subConfig = subConfigs[subConfigKey];
            const dataObjectSubConfigId = `${dataObjectId}_${subConfigKey}`;

            if (!subConfigToDataObject.has(dataObjectSubConfigId)) {
                subConfigToDataObject.set(dataObjectSubConfigId, dataObjectId);
            }

            if (!masterDetailMapping.has(dataObjectSubConfigId)) {
                masterDetailMapping.set(dataObjectSubConfigId, subConfig.masterDataObject_ID);
            }

            if (subConfig.masterDataObject_ID) {
                const masterDataObject = dataObjectStore.get(app.id)!.get(subConfig.masterDataObject_ID)?.value;

                if (!masterDataObject) {

                    if (masterDetailMapping.has(dataObjectSubConfigId)) {
                        let key: string | undefined = dataObjectSubConfigId;

                        do {
                            key = masterDetailMapping.get(key);

                            if (key && subConfigToDataObject.has(key) && subConfigToDataObject.get(key) === dataObjectId) {
                                throw new Error(`Failed to configure data object (${dataObjectSubConfigId}). One of the nested MasterDetail DataObjects has the same original DataObject (${dataObjectId})`);
                            }
                        } while (key && key !== dataObjectSubConfigId);

                        if (key === dataObjectSubConfigId) {
                            throw new Error(`Failed to configure data object (${dataObjectSubConfigId}). The nested MasterDetail DataObjects ends up back at the same DataObject`);
                        }
                    }

                    // Move current entry to the end of the array
                    dataObjectConfigs.push(dataObjectConfigs.splice(index, 1)[0]);
                    // Set flag to indicate that we should not increment i
                    moveToEnd = true;
                    break;
                }
            }
        }
        return moveToEnd;
    }

    function createSyncObject(dataObjectId: string, dataObjectConfig: any): void {
        const syncDataObjectId = dataObjectId + '_sync';
        const syncConfig = Object.assign({}, dataObjectConfig, { id: syncDataObjectId, appId: app.id });

        app.dataObjectConfigs.set(syncDataObjectId, syncConfig);

        const syncObject = getOrCreateDataObject(syncConfig, app.id);

        syncObject.enableOffline();
    }

    function createSubConfigs(dataObjectConfig: any, dataObjectId: string) {
        for (const subConfig of Object.keys(dataObjectConfig.offline?.subConfigs)) {
            const dataObjectSubConfigId = `${dataObjectId}_${subConfig}`;
            const dataObjectSubConfig = Object.assign({}, dataObjectConfig, dataObjectConfig.offline?.subConfigs[subConfig], { id: dataObjectSubConfigId, appId: app.id });

            app.dataObjectConfigs.set(dataObjectSubConfigId, dataObjectSubConfig);

            const subDataObject = getOrCreateDataObject(dataObjectSubConfig, app.id);

            subDataObject.enableOffline();
        }
    }

    async function createObjectStore(dataObject: any, dataObjectConfig: any, objectStoreId: any, idbDatabaseCache: any, idbObjectStore: any, idbDatabase: any, idbApp: any) {
        let fields: Array<string>;

        try {
            fields = dataObjectConfig.fields.map((field: any) => field.name);
        } catch (reason) {
            console.error(reason);

            fields = new Array();
        }

        if (idbObjectStore === null) {
            idbObjectStore = await IndexedDBHandler.createObjectStore(idbApp.id, idbDatabase.id, objectStoreId, dataObject.offline.jsonDataVersion, fields);
        } else if (
            idbObjectStore.jsonDataVersion !== dataObject.offline.jsonDataVersion ||
            idbObjectStore.fields?.length !== fields.length ||
            new Set([...idbObjectStore.fields, ...fields]).size !== (new Set(fields)).size
        ) {
            idbObjectStore.jsonDataVersion = dataObject.offline.jsonDataVersion;
            idbObjectStore.fields = fields;

            await idbObjectStore.save();
        }

        if (idbDatabaseCache.objectStores.has(idbObjectStore.id) === false) {
            idbDatabaseCache.objectStores.set(idbObjectStore.id, {
                value: idbObjectStore,
                indexes: new Map()
            });
        }
    }

    async function createIndexes(dataObject: any, idbDatabaseCache: any, idbObjectStore: any, idbApp: any, idbDatabase: any) {
        let idbObjectStoreCache = idbDatabaseCache.objectStores.get(idbObjectStore.id)!;

        const indexConfigs = dataObject.offline.indexedDBIndexes;

        for (const indexConfig of indexConfigs) {
            let idbIndex = await IndexedDBHandler.getIndex(idbApp.id, idbDatabase.id, idbObjectStore.id, indexConfig.id);

            if (idbIndex === null) {
                idbIndex = await IndexedDBHandler.createIndex(
                    idbApp.id,
                    idbDatabase.id,
                    idbObjectStore.id,
                    indexConfig.id,
                    indexConfig.keyPath,
                    indexConfig.isPrimaryKey,
                    indexConfig.isUnique,
                    indexConfig.isMultiEntry,
                    indexConfig.isAutoIncrement
                );
            }

            idbObjectStoreCache.indexes.set(idbIndex.id, {
                value: idbIndex
            });
        }
    }

    async function getApp(): Promise<App> {
        let idbApp = await IndexedDBHandler.getApp(app.id);

        if (idbApp === null) {
            idbApp = await IndexedDBHandler.createApp(app.id);
        }

        return idbApp;
    }

    async function getIndexedDbDatabases(idbApp: App): Promise<Map<string, IIDbDatabase>> {
        const indexedDbDatabases = new Map<string, IIDbDatabase>();
        
        indexedDbDatabases.set('DEFAULT', {
            value: (await idbApp.databases['DEFAULT']) ?? (await IndexedDBHandler.createDatabase(idbApp.id, 'DEFAULT')),
            objectStores: new Map()
        });

        return indexedDbDatabases;
    }
}

export default DataObjectInitializer;